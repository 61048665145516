// Header.js
import React from 'react';
import About from './About'


const Header = () => {
  return (
    <About />
    
  );
};

export default Header;